import { useFontSelection } from 'api/queries/useFontSelection';
import { CommandBatch } from 'commands/Commands';
import { ChangeFontCommand } from 'commands/business/ChangeFontCommand';
import { EditContext } from 'context/EditContext';
import { Fonts } from 'models/business';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BusinessEditControls from '../BusinessEditControls';

interface Props {
	showControls?: boolean;
	label: string;
	fontType: 'paragraph' | 'header' | 'navBarFooter' | 'ctaButton';
	filterWeight?: 'regular' | 'italic' | '100' | '200' | '300' | '500' | '600' | '700' | '800' | '900',
	commands: CommandBatch;
	selectedParagraphFont?: string;
	selectedFont?: string;
	selectedWeight?: string;
}

const BusinessFontSelector: React.FC<Props> = ({
	label,
	fontType,
	filterWeight,
	commands,
	selectedFont = '',
	selectedWeight = ''
}) => {
	const { businessName } = useParams<{ businessName: string }>();
	const { data: fontSelection } = useFontSelection(businessName);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { setDirty } = useContext(EditContext);
	const [fonts, setFonts] = useState<Fonts[]>([]);
	const [fontSelected, setSelectedFont] = useState<string>(selectedFont && selectedWeight ? `${selectedFont} ${selectedWeight}` : " ");
	const regular = "regular";
	const defaultFont = "Default font";

	useEffect(() => {
		if (selectedFont) {
			let combinedFont = selectedFont;
			if (selectedWeight) {
				combinedFont += `|${selectedWeight}`;
			} else if (filterWeight) {
				combinedFont += `|${filterWeight}`;
			}
			setSelectedFont(combinedFont);
		} else {
			setSelectedFont(defaultFont);
		}
	}, [selectedFont, selectedWeight]);

	const fetchFonts = useCallback(async () => {
		if (fontSelection) {
			const allFilteredFonts = fontSelection.map((item) => ({
				family: item.family,
				variants: item.variants.filter((variant) => !variant.includes('italic')),
			}));
			setFonts(allFilteredFonts);
		}
	}, [fontSelection]);

	const filteredFonts = useMemo(() => {
		if (!filterWeight || filterWeight !== regular) return fonts;
		return fonts
			.map((font) => ({
				family: font.family,
				variants: font.variants.filter((variant) => variant === regular),
			}))
			.filter((font) => font.variants.length > 0);
	}, [fonts, filterWeight]);

	useEffect(() => {
		fetchFonts();
	}, [fetchFonts]);

	const handleFontChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		let [family, weight] = e.target.value.split('|');

		if (family === defaultFont)
			family = '';

		if (family !== selectedFont || weight !== selectedWeight) {
			setDirty(true);
			commands.add(new ChangeFontCommand([fontType, family, weight], fontType));
		} else {
			setDirty(false);
		}
		setSelectedFont(e.target.value);
	};

	return (
		<>
			<div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
				<h3 className='block text-lg text-gray-700 p-1'>{label}</h3>
				<div className='flex'>
					{!isEditing ? (
						<span className='px-3 py-1 bg-gray-100 rounded-full'>
							{fontSelected
								? (filterWeight === regular
									? fontSelected.split('|')[0]
									: fontSelected.split('|').join(' ')
								)
								: (selectedFont && selectedWeight
									? `${selectedFont} ${selectedWeight}`
									: " "
								)
							}
						</span>
					) : (
						<select
							className="mx-3 bg-gray-100 rounded-md p-1"
							value={fontSelected || ''}
							onChange={handleFontChange}
						>
							<option value="Default font| ">Default font</option>
							<option value="" disabled>-----------</option>
							{filteredFonts.flatMap((font) =>
								font.variants.map((variant) => (
									<option key={`${font.family}-${variant}`} value={`${font.family}|${variant}`}>
										{filterWeight === regular ? font.family : `${font.family} ${variant}`}
									</option>
								))
							)}
						</select>
					)}
					<BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} />
				</div>
			</div>
		</>
	);
};

export default BusinessFontSelector;